import { useContext, useState } from 'react';
import './style.css'
import { AppContext } from '../../../../AppContext';

export default function FormDv() {

    const { urlAtual } = useContext(AppContext);
    const [mensagemBtn, setMensagemBtn] = useState('Enviar');
    const [emailEnviado, setEmailEnviado] = useState(true)
    const [dadosEmail, setDadosEmail] = useState({
        nome: '',
        email: '',
        telefone: '',
        mensagem: ''
    });



    const enviarEmailParaAPI = async () => {
        try {
            const response = await fetch(`${urlAtual}/enviar`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...dadosEmail, tipo: "Não encontrei o que achava, obs: msn padrão paa diferenciar das cotações" }),
            });

            if (response.ok) {
                setDadosEmail({
                    nome: '',
                    email: '',
                    telefone: '',
                    mensagem: ''
                });
                setMensagemBtn("Enviar")
                setEmailEnviado(!emailEnviado)
            } else {
                console.error('Erro ao enviar e-mail');
                setEmailEnviado(!emailEnviado)
            }
        } catch (error) {
            console.error('Erro na requisição:', error);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setDadosEmail((prevDados) => ({
            ...prevDados,
            [name]: value,
        }));
    };

    const solicitarEmail = (e) => {
        e.preventDefault();
        enviarEmailParaAPI();
        setMensagemBtn(
            <>
                <h3 className='text-center fs-5'>Enviando <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div></h3>
            </>
        );
    }
    return (
        <section className='mt-4 duvidasPai' id='mensagem'>
            <h1 className="infH1 text-center tft">Não encontrou o que procurava?</h1>
            <div className='mx-auto my-2 mb-5' style={{display: emailEnviado? 'block': 'none'}}>
                <p className='tfp text-center px-3'>Preencha o formulário abaixo que em breve alguém da equipe vai entrar em contato</p>
                <div className='formDvBody mx-auto formularioDuvidas px-4 py-5 rounded-4'>
                    <form onSubmit={solicitarEmail}>
                        <div className="form-floating mb-3">
                            <input type="text"
                             className="form-control" 
                             id="floatingInput" 
                             placeholder="digeite seu nome" 
                             name='nome'
                             onChange={handleChange}
                                 required
                             />
                            <label for="floatingInput">Nome</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="number" 
                            className="form-control" 
                            id="floatingInput" 
                            placeholder="digite seu telefone"
                            name='telefone'
                            onChange={handleChange}
                                required
                            />
                            <label for="floatingInput">Telefone</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input 
                            type="email" 
                            className="form-control" 
                            id="floatingInput" 
                            placeholder="name@example.com" 
                            name='email'
                            onChange={handleChange}
                                required
                            />
                            <label for="floatingInput">E-mail</label>
                        </div>
                        <div className="form-floating">
                            <textarea 
                            className="form-control" 
                            placeholder="Leave a comment here" 
                            id="floatingTextarea"
                            name='mensagem'
                            onChange={handleChange}
                                required
                            ></textarea>
                            <label for="floatingTextarea">Digite sua mensagem</label>
                        </div>
                        <div className='d-flex mt-3 justify-content-end'>
                            <button type="submit" className="btn btn-success">{mensagemBtn}</button>
                        </div>
                    </form>
                </div>
            </div>
            <div style={{display: !emailEnviado? 'block': 'none'}}>
                <h3 className='mensagemEnv'>
                    Sua mensagem foi enviada, em breve entraremos em contato!
                </h3>
            </div>
        </section>
    )
}
