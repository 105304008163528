import { useState, useContext, useRef } from 'react';
import dadosDuvidas from './dados.json';
import { AppContext } from "../../../../AppContext";
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from "react-icons/bs";
import { FaRegDotCircle, FaDotCircle } from "react-icons/fa";
import './style.css';
import { useSwipeable } from 'react-swipeable';
import { useMediaQuery } from 'react-responsive';
export default function Modelos() {
    const { ativarBotao } = useContext(AppContext);
    const [currentIndex, setCurrentIndex] = useState(0);
    const containerRef = useRef(null);
    const deslizar = useSwipeable({
        onSwipedLeft: () => proxima(),
        onSwipedRight: () => anterior(),
    });
    const telaP = useMediaQuery({ query: '(max-width: 800px)' });

    function updatePosition(index) {
        const container = containerRef.current;
        if (container) {
            container.style.transform = `translateX(-${index * 100}%)`;
        }
    }

    function anterior() {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => {
                const newIndex = prevIndex - 1;
                updatePosition(newIndex);
                return newIndex;
            });
        }
    }

    function proxima() {
        if (currentIndex < 4) {
            setCurrentIndex((prevIndex) => {
                const newIndex = prevIndex + 1;
                updatePosition(newIndex);
                return newIndex;
            });
        }
    }

    return (
        <div className="modelosPai" {...deslizar}>
            <div className="modelosPai_titulo">
                <h1 className="infH1">Confira alguns de nossos trabalhos <img src="/imagens/duvidas/titulos/verificacao.png" className="imgConf ms-4" alt="" /></h1>
            </div>
            <div className='modeloUnic' ref={containerRef}>
                {dadosDuvidas.map(item => (
                    <div className="cardsModelos" key={item.id}>
                        <img src={`/imagens/modelos/${item.src}`} className="card-img-top imgLogo"
                            alt="comercial" />
                        <div className="card-body modelosTexto text-center m-0 p-0">
                            <div className="cardsModelos_titulos">
                                <p className="card-text fw-bold my-auto">{item.titulo}</p>
                                <p className="card-text fw-bold my-auto">{`Tipo: ${item.tipo}`}</p>
                            </div>
                            <a href={item.href} target="_blank" rel="noreferrer">
                                <button
                                    type="button"
                                    onClick={() => { ativarBotao(`Entrei no site ${item.titulo}`) }}
                                >Acessar</button>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
            <div className='marcadores'>
                {dadosDuvidas.map(item => (
                    <div key={item.id} >
                        {currentIndex + 1 === item.id ?
                            <FaRegDotCircle /> : <FaDotCircle />
                        }
                    </div>
                ))}
            </div>

            {telaP === false &&
                <div className="setas">
                    <BsFillArrowLeftCircleFill
                        size={60}
                        onClick={anterior}
                        className="setaVoltar2"
                        style={{ left: '5vw' }}
                    />

                    <BsFillArrowRightCircleFill
                        size={60}
                        onClick={proxima}
                        className="setaProxima2"
                        style={{ right: '5vw' }}
                    />

                </div>
            }

        </div>
    )
}
