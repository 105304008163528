import React, { useContext } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Header.css';
import { AppContext } from '../../../../AppContext';
import NavHeader from './nav';
import { Link } from 'react-router-dom';
import { MdMarkEmailRead } from "react-icons/md";

const Header = () => {
    const { ativarBotao } = useContext(AppContext);
    return (
        <div className='HeaderMain'>
            <div className="container" >
                <div className="stars" ></div>
                <div className="stars2" ></div>
            </div>
            <div className='emVolta d-flex flex-column align-items-center'>
                <NavHeader />
                <div className="tituloHeader px-3 pb-0 pb-sm-5">
                    <div className='tituloHeaderMain'>
                        <h1 className="text-center">
                            <h1 className="text-center textoH1Fonte fonteTituloHeader mx-auto">Conecte-se ao futuro com um site que reflete a essência da sua marca.</h1>
                        </h1>
                        <p>Quer se destacar e superar seus concorrentes? Dê um passo à frente e comece a vender online agora mesmo!<br/>Solicite já seu orçamento e entre de vez no mercado digital!</p>
                        <p></p>
                        <Link to='/tipos-de-sites'>
                            <button type="button" className="btn btn-primary fs-4 btn-lg mb-2 btnCotacao" onClick={() => ativarBotao("Click no botão de orçamento")}>Solicite seu orçamento <MdMarkEmailRead /></button>
                        </Link>
                    </div>
                    <img src='/imagens/header/telaBack1.png' alt='tela de fundo'/>
                </div>
            </div >
        </div >
    )
}

export default Header;
