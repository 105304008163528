import { useContext, useEffect, useState } from 'react';
import './Rodape.css'
import { AppContext } from '../../../../AppContext';
import { DadosJson } from '../../../../assets/dados'
import { Link } from 'react-router-dom';

const Rodape = () => {

    const { CliqueWhats, CliqueNoIsta, CliqueNoFace, ativarBotao } = useContext(AppContext);

    const [showWhatsapp, setShowWhatsapp] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;
            const scrollPosition = window.scrollY;
            const percentageScrolled = (scrollPosition / (scrollHeight - clientHeight)) * 100;

            setShowWhatsapp(percentageScrolled <= 90);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='rodape'>
            <div className='rodape1pt'>
                <div className='rodape1pt_logo'>
                    <img src='/imagens/rodape/logoRodape.png' alt='logo' className='logoRodape' />
                    <h2>Seu site rápido, responsivo e com layout diferenciado!</h2>
                </div>
                <div className='rodape1ptTextos'>
                    <div className='rodapeTextos'>
                        <h4>Tipos de sites</h4>
                        {DadosJson.map(item => (
                            <Link to={`/cotacao/${item.id}`}>
                                <p
                                    onClick={() => {
                                        ativarBotao(`Entrei no formulario ${item.nome}`)
                                    }}
                                >{item.nome}</p>
                            </Link>
                        ))}
                    </div>
                    <div className='rodapeTextos'>
                        <h4>Fale conosco</h4>
                        <p onClick={CliqueWhats}>Dúvidas</p>
                        <p onClick={CliqueWhats}>Sugestões</p>
                        <p onClick={CliqueWhats}>Reclamações</p>
                    </div>
                    <div className="rodapeTextos">
                        <h4>Pagamentos</h4>
                        <p data-bs-toggle="modal" data-bs-target="#pagamentoPopUp">Pix <i className="ms-1 bi bi-x-diamond"></i></p>
                        <p data-bs-toggle="modal" data-bs-target="#pagamentoPopUp">Crédito <i className="ms-1 bi bi-credit-card"></i></p>
                        <p data-bs-toggle="modal" data-bs-target="#pagamentoPopUp">Débito <i className="bi bi-credit-card-2-back"></i></p>
                        <p data-bs-toggle="modal" data-bs-target="#pagamentoPopUp">Boleto <i className="ms-1 bi bi-file-earmark-break"></i></p>
                    </div>
                </div>
            </div>
            <div className='rodape2pt'>
                <div />
            </div>
            <div className='rodape3pt'>
                <p>JSweb © 2023 todos os direitos reservados</p>
                <p>Rua santa isaira Qd: 10 Lt: 26 Bairro paraíso 75134235 - Anápolis-GO</p>
                <a href="mailto:jsilvawebsite@gmail.com">✉ jsilvawebsite@gmail.com</a>
                <div className="redSocMainRod">
                    <img src="/imagens/header/redeSocial/instagram.png" onClick={CliqueNoIsta} className="redSocIcon" alt="Logo" />
                    <img src="/imagens/header/redeSocial/facebook.png" onClick={CliqueNoFace} className="redSocIcon" alt="Logo" />
                </div>
            </div>
            <img src="/imagens/header/redeSocial/whatsapp.png" onClick={CliqueWhats} className="WhatsFix" alt="Logo" style={{ display: showWhatsapp ? 'block' : 'none' }} />
            <div className="modal fade" id="pagamentoPopUp" tabindex="-1" aria-labelledby="pagamentoPopUpLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="pagamentoPopUpLabel">Pagamentos</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Após solicitar o orçamento, nossa equipe enviará a proposta. Se ambas as partes concordarem, enviaremos
                            o link para pagamento.
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Rodape
