import './TiposSites.css';
import { DadosJson } from '../../../../assets/dados';
import { Link } from 'react-router-dom';
export default function TipoSite() {
    return (
        <div className='tipoSite'>
            <h2>Tipos de sites</h2>
            {DadosJson.map(itens => (
                <div className='tipoSite_itens'>
                    <div key={itens.id} className='tipoSite_itens_dados'>
                        <Link to={`/cotacao/${itens.id}`}><h1>{itens.nome}</h1></Link>
                        <p>{itens.descricao}</p>
                        <Link to={`/cotacao/${itens.id}`}>
                            <button >Solicitar</button>
                        </Link>
                    </div>
                    <img src={`/imagens/duvidas/modelos/${itens.src}.jpeg`} alt={itens.descricao} />
                </div>
            ))}
        </div>
    )
}