import './style.css'
import { Helmet } from 'react-helmet';
import NavHeader from '../mainPage/componentes/header/nav';
import Rodape from '../mainPage/componentes/rodape';
import TipoSite from './componentes/tiposDeSite';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';

export default function TipoSitesCompl() {
    const { ativarBotao } = useContext(AppContext);
    useEffect(() => {
        ativarBotao('Acesso a pagina tipos de site');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <section className="vendasCss">
            <Helmet>
                <title>Tipo de sites</title>
                <link rel="icon" href="/imagens/header/logo/logoIcon.png"></link>
                <meta name="description" content="Precisa aumentar suas vendas? A solução está em um site profissional. Transforme sua presença online com design personalizado, tecnologia de ponta, SEO, responsividade e suporte contínuo. JSweb - Conectando seu negócio ao futuro digital."/>
                <link rel="canonical" href="https://jsweb-sites.com/tipos-de-sites"/>
            </Helmet>
            <main className="vendasCssMain">
                <NavHeader />
                <TipoSite />
                <Rodape />
            </main>
        </section>
    )
}
