import './MainPage.css'
import 'bootstrap/dist/css/bootstrap.css';
// eslint-disable-next-line no-unused-vars
import bootstrap from 'bootstrap'
import { useMediaQuery } from 'react-responsive'
import { AppProvider } from "../AppContext"
import Rodape from "../paginas/mainPage/componentes/rodape"
import Header from "../paginas/mainPage/componentes/header/telaPC"
import Cotacao from '../paginas/mainPage/componentes/cotacao';
import FormDv from '../paginas/mainPage/componentes/formularioDv';
import Modelos from '../paginas/mainPage/componentes/modelos';
import Informativo from '../paginas/mainPage/componentes/informativos';
import HeaderST from '../paginas/mainPage/componentes/header/telaCel';

const PaginaPrincipal = () => {

    const telaPequena = useMediaQuery({ query: '(max-width: 700px)' });

    return (
        <AppProvider>
            <main className='paginaPrincipal'>
                {telaPequena ?
                    <HeaderST /> : <Header />
                }
                <Cotacao />
                <Informativo />
                <FormDv />
                <Modelos />
                <Rodape />
            </main>
        </AppProvider>
    )
}

export default PaginaPrincipal
