export const DadosJson = [
    {
        nome: "Loja virtual (Vendas online)",
        src: "comercial",
        descricao: "Plataforma de comércio online que oferece aos comerciantes a oportunidade de exibir e vender seus produtos, conectando-os a potenciais compradores através da internet. Nosso site é facilmente acessível tanto por computadores (desktops/notebooks) quanto por dispositivos móveis (telefones/tablets), proporcionando uma experiência de compra conveniente e flexível para todos os usuários.",
        id: 1
    },
    {
        nome: "Institucional",
        src: "institucional",
        descricao: "Plataforma dedicada a instituições e empresas, oferecendo um espaço para divulgar informações relevantes ao seu grupo. Entre os conteúdos possíveis estão a missão, valores, equipe de colaboradores, portfólio, registros, história e outros elementos essenciais. O site proporciona uma maneira eficaz e organizada de compartilhar detalhes importantes, fortalecendo a presença online e promovendo uma comunicação transparente com o público-alvo.",
        id: 2
    }, 
    {
        nome: "One page",
        src: "onepage",
        descricao: "Comumente utilizado por profissionais autônomos ou microempresas, este formato destina-se a apresentar informações concisas que se encaixam em uma única página. Essas informações podem incluir detalhes sobre os serviços oferecidos, portfólio e outros elementos relevantes, proporcionando uma visão rápida e abrangente do profissional ou da empresa. Ideal para uma apresentação sucinta e impactante.",
        id: 3
    },
    {
        nome: "Landing page",
        src: "landingPage",
        descricao: "Uma landing page é uma página da web projetada especificamente para receber o tráfego direcionado de uma fonte externa, como anúncios, campanhas de marketing por e-mail ou links de mídia social. Seu principal objetivo é converter os visitantes em leads ou clientes, fornecendo informações relevantes e persuasivas sobre um produto, serviço ou oferta específica.",
        id: 4
    }, {
        nome: "Orçamentario",
        src: "orcamento",
        descricao: "Website dedicado a orçamentos, visa destacar o portfólio da sua empresa e incentivar os clientes a solicitarem propostas, facilitando a negociação dos valores dos produtos ou serviços.",
        id: 5
    }, {
        nome: "Outros",
        src: "outros",
        descricao: "Informe em 'Outras informações' o site que você deseja.",
        id: 6
    },
]
