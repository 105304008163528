import NavHeader from "./nav";
import './Header.css'
import { FaInstagram, FaFacebookSquare } from "react-icons/fa";
import { useContext } from "react";
import { AppContext } from "../../../../AppContext";
import { Link } from "react-router-dom";
import { MdMarkEmailRead } from "react-icons/md";

export default function HeaderST() {
    const { CliqueNoIsta, CliqueNoFace, ativarBotao } = useContext(AppContext);
    return (
        <div style={{ height: 'auto', display: 'flex', flexDirection: 'column' }}>
            <NavHeader />
            <div className="imgInfsCel">
                <img src="/imagens/infsCel.png" alt="" />
                <div className="redesSociaisSiganos">
                    <h2>Siga-nos nas redes sociais!</h2>
                    <div className="redesSociaisSiganos_logos">
                        <FaInstagram onClick={CliqueNoIsta} />
                        <FaFacebookSquare onClick={CliqueNoFace} />
                    </div>
                    <Link to='/tipos-de-sites'>
                        <button type="button" className="btnCotacao" onClick={() => ativarBotao("Click no botão de orçamento")}>Solicite seu orçamento <MdMarkEmailRead /></button>
                    </Link>
                </div>
            </div>
        </div>
    )
}
