import './style.css'
import NavHeader from "../mainPage/componentes/header/nav";
import Rodape from "../mainPage/componentes/rodape";
import VendasBody from './componentes/vendas';
import { Helmet } from 'react-helmet';
import TipoSite from './componentes/tiposDeSite';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';

export default function VendaSites() {
    const { ativarBotao } = useContext(AppContext);
    useEffect(() => {
        ativarBotao('Acesso a pagina venda de site');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <section className="vendasCss">
            <Helmet>
                <link rel="icon" href="/imagens/header/logo/logoIcon.png"></link>
                <title>Venda de sites pronto | Venda de sites para venda online</title>
                <meta name="description" content="Precisa aumentar suas vendas? A solução está em um site profissional. Transforme sua presença online com design personalizado, tecnologia de ponta, SEO, responsividade e suporte contínuo. JSweb - Conectando seu negócio ao futuro digital." />
                <link rel="canonical" href="https://jsweb-sites.com/venda-de-sites" />
            </Helmet>
            <main className="vendasCssMain">
                <NavHeader />
                <VendasBody />
                <TipoSite />
                <Rodape />
            </main>
        </section>
    )
}
