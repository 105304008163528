import { AppProvider } from "../AppContext";
import VendaSites from "../paginas/venda&tipoSites/vendasSites";

export default function VendaSiteRota(){
    return(
        <AppProvider>
            <VendaSites/>
        </AppProvider>
    )
}
