import './Nav.css'
import { Link } from 'react-router-dom';
import { RxHamburgerMenu } from "react-icons/rx";
import { useContext } from 'react';
import { AppContext } from '../../../../../AppContext';
export default function NavHeader() {
  
    const { CliqueWhats, CliqueNoIsta, CliqueNoFace } = useContext(AppContext);
    return (
        <nav className="navbar navbar-expand-md w-100">
            <div className="container-fluid">
                <img src="/imagens\rodape\logoRodape.png" alt="logo" width="100" height="100" />
                <button className='bg-transparent btHamb' type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <RxHamburgerMenu className='colorText' size={45} />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                <button className="nav-link colorText fontTextNav ms-0 ms-sm-4">Pagina inicial</button>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link colorText fontTextNav ms-0 ms-sm-4" onClick={CliqueWhats}>Fale conosco <i className="bi bi-whatsapp"></i></button>
                        </li>
                        <Link to="/tipos-de-sites" style={{ textDecoration: 'none' }}>
                            <li className="nav-item">
                                <button className="nav-link colorText fontTextNav ms-0 ms-sm-4">Solicitar orçamento</button>
                            </li>
                        </Link>
                    </ul>
                    <div className="d-flex align-items-center me-5">
                        <i className="bi bi-instagram fs-2 me-3 colorText" onClick={CliqueNoIsta}></i>
                        <i className="bi bi-facebook fs-2 colorText" onClick={CliqueNoFace}></i>
                    </div>
                </div>
            </div>
        </nav>
    )
}