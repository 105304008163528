import { useContext } from "react";
import './style.css'
import { AppContext } from "../../../../AppContext";

export default function Cotacao() {

    const { CliqueWhats } = useContext(AppContext);
 
    return (
        <section className="pagina d-flex align-items-center flex-column justify-content-between" id="pagina">
            <div className="cob"></div>
            <div className="paginaTexto">
                <div className="paginaTexto1">
                    <h1 className="tftc pt-4">Não perca tempo, solicite já o orçamento do seu site!</h1>
                </div>
                <div className="paginaTexto2">
                    <h1 className="tfp py-5">Na JS Web, estamos comprometidos em construir a presença digital da sua empresa de
                        forma eficaz,
                        impulsionando suas vendas e expandindo seus horizontes. Não perca mais tempo, solicite agora mesmo
                        seu orçamento e faça parte do mundo digital. Descubra como a distância já não é mais um obstáculo
                        para alcançar novos clientes e aumentar seus lucros. Estamos aqui para transformar sua visão em
                        realidade e fazer seu negócio prosperar online.</h1>
                </div>
            </div>
            <button type="button" className="btn btn-success bottom-0 mb-4 fs-3 mt-4 mt-sm-0" onClick={CliqueWhats}>Entre em contato <i
                className="bi bi-whatsapp"></i></button>
        </section>
    )
}
