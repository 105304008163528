import './MainPage.css'
import { AppProvider } from "../AppContext";
import Formulario from "../paginas/cotacao/componentes/formulario";
import NavHeader from "../paginas/mainPage/componentes/header/nav";
import Rodape from "../paginas/mainPage/componentes/rodape";

export default function Cotacao() {
    return (
        <AppProvider>
            <main className='cotacaoMainPage' >
                <NavHeader />
                <Formulario />
                <Rodape />
            </main>
        </AppProvider>
    )
}
