import React, { createContext, useState } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [sairForm, setSairForm] = useState(null);
  const [tipoSite, setTipoSite] = useState(null);
  const [srcSite, setSrcSite] = useState(null);
  const [descSite, setDescSite] = useState(null);
  const hostUrl = process.env.REACT_APP_URLATUAL;

  const phoneNumber = '62993129673';
  const message = 'Olá, como posso ajudar?';
  const urlAtual = hostUrl

  const ativarBotao = async (botao) => {
    try {
      const dataHoraAtual = new Date();
      const dataISO = dataHoraAtual.toISOString();
      const horaISO = dataHoraAtual.toTimeString().split(' ')[0];
  
      const response = await fetch(`${urlAtual}/salvar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          botao: botao,
          data: dataISO,
          hora: horaISO
        }),
      });
  
      if (response.ok) {
        
      } else {
        console.error('Erro ao enviar dados:', response.statusText);
      }
    } catch(error) {
      console.error('Erro ao realizar requisição:', error);
    }
  };
const CliqueWhats = () => {
  const isMobile = window.innerWidth <= 700;
  const baseUrl = isMobile ? `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`
    : `https://web.whatsapp.com/send?phone=${encodeURIComponent(phoneNumber)}&text=${encodeURIComponent(message)}`
  window.open(baseUrl, '_blank');
  ativarBotao('WhatsApp')
};
const CliqueNoIsta = () => {
  const instagram = "https://www.instagram.com/jswebprogramador?igsh=MWl5dnRqdHg1bjM2OQ=="
  window.open(instagram, '_blank');
  ativarBotao('Instagram')
}
const CliqueNoFace = () => {
  const facebook = "https://www.facebook.com/profile.php?id=61553007513798&mibextid=ZbWKwL"
  window.open(facebook, '_blank');
  ativarBotao('Facebook')
}

return (
  <AppContext.Provider value={{ sairForm, tipoSite, srcSite, descSite, setSairForm, setTipoSite, setSrcSite, setDescSite, CliqueNoFace, CliqueNoIsta, CliqueWhats, urlAtual, ativarBotao }}>
    {children}
  </AppContext.Provider>
);
};
