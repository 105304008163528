import { AppProvider } from "../AppContext";
import TipoSitesCompl from "../paginas/venda&tipoSites/tiposDeSites";

export default function TipoSiteRota() {
    
    return (
        <AppProvider>
            <TipoSitesCompl />
        </AppProvider>
    )
}
