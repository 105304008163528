import './style.css'
import dados from './dados.json'
import { useContext, useEffect } from 'react';
import { AppContext } from '../../../../AppContext';

export default function Informativo(){
    const { ativarBotao } = useContext(AppContext);
    useEffect(() => {
        ativarBotao('Acesso a pagina Principal');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return(
        <section className="dadoSec">
            {dados.map((item)=> (
                <div key={item.titulo}>
                    <img src={`imagens/informativos/${item.src}`} alt={item.titulo}/>
                    <h1>{item.titulo}</h1>
                    <p>{item.texto}</p>
                </div>
            ))}
        </section>
    )
}
