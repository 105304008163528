
import './Formulario.css'
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import { useParams } from 'react-router-dom';
import { DadosJson } from '../../../../assets/dados';

const Formulario = () => {
    const { id } = useParams();
    const dadosSite = DadosJson.filter(item => item.id === parseInt(id));
    const { urlAtual, ativarBotao } = useContext(AppContext);
    const [dadosEmail, setDadosEmail] = useState({
        nome: '',
        email: '',
        telefone: '',
        mensagem: ''
    });
    const [mensagemBtn, setMensagemBtn] = useState('Solicitar Cotação');
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);

    const enviarEmailParaAPI = async () => {
        try {
            const response = await fetch(`${urlAtual}/enviar`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...dadosEmail, tipo: dadosSite.map(item => item.nome) }),
            });

            if (response.ok) {
                setDadosEmail({
                    nome: '',
                    email: '',
                    telefone: '',
                    tipo: '',
                    mensagem: ''
                });
                setMensagemBtn("Solicitar Cotação");
                alert('Mensagem enviada com sucesso');
                ativarBotao(`Solicitação feita ${dadosEmail.nome}`);
                window.location.href = "/";
            } else {
                console.error('Erro ao enviar e-mail');
            }
        } catch (error) {
            console.error('Erro na requisição:', error);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setDadosEmail((prevDados) => ({
            ...prevDados,
            [name]: value,
        }));
    };

    const solicitarEmail = (e) => {
        e.preventDefault();
        enviarEmailParaAPI();
        setMensagemBtn(
            <>
                <h3 className='text-center fs-5'>Enviando <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div></h3>
            </>
        );
    }

    return (
        <div className='formularioMain py-2'>
            <div className='px-0 px-sm-5 pagFormMain'>
                <div className='formularioCot p-3 rounded-3'>
                    <form onSubmit={solicitarEmail} className='d-flex flex-column align-items-center w-100'>
                        <h2 className='text-center text-light'>Preencha os dados do formulário <i className="bi bi-file-earmark-text"></i></h2>
                        <ul className='w-100 p-0'>
                            <li>
                                <div className="form-floating mb-3 ms-0">
                                    <input type="text"
                                        name='nome'
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="digite seu nome"
                                        value={dadosEmail.nome}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label for="floatingInput">Digite seu nome</label>
                                </div>
                            </li>
                            <li>
                                <div className="form-floating mb-3">
                                    <input type="tel"
                                        name='telefone'
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="digite seu telefone"
                                        value={dadosEmail.telefone}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label for="floatingInput">Digite seu Telefone</label>
                                </div>
                            </li>
                            <li>
                                <div className="form-floating mb-3">
                                    <input type="email"
                                        name='email'
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="digite seu email"
                                        value={dadosEmail.email}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label for="floatingInput">Digite seu e-mail</label>
                                </div>
                            </li>
                            <li>
                                <div className="form-floating">
                                    <textarea
                                        name='mensagem'
                                        className="form-control mb-3"
                                        placeholder="Leave a comment here"
                                        id="floatingTextarea"
                                        value={dadosEmail.mensagem}
                                        onChange={handleChange} />
                                    <label for="floatingTextarea">Outras Informações</label>
                                </div>
                            </li>
                            <div className='d-flex justify-content-end'>
                                <button type="submit" className='btn btn-success'>{mensagemBtn}</button>
                            </div>
                        </ul>
                    </form>
                </div>
                <div className='informacoesCot rounded-3 p-3 text-light'>
                    {dadosSite.map(item => (
                        <div key={item.id} className='dadosSitesSoli'>
                            <img src={`/imagens/duvidas/modelos/${item.src}.jpeg`} alt={item.nome} className='rounded-3' />
                            <div>
                                <h4>{item.nome}</h4>
                                <p>{item.descricao}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div></div>
        </div>
    )
}

export default Formulario
