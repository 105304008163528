import './Vendas.css'

export default function VendasBody() {
    return (
        <div className='vendasBody'>
            <h1>Solicite o orçamento de seu site</h1>
            <p>
                Na JSweb, desenvolvemos sites de alta qualidade e rapidez, projetados
                para destacar sua marca e atender às suas necessidades específicas.
                Nossos sites são rápidos, responsivos e otimizados para SEO,
                garantindo uma experiência de usuário excepcional e uma maior
                visibilidade nos motores de busca. Oferecemos designs exclusivos,
                fáceis de gerenciar e com funcionalidades avançadas, além de suporte
                técnico dedicado e atendimento personalizado. Transforme sua presença
                online com um site da JSweb e impulsione seu negócio para o sucesso!
            </p>
        </div>
    )
}